import { RemoteRunnable } from "@langchain/core/runnables/remote";
import { useEffect, useState } from "react";
import ChatWindow from "./components/ChatWindow";
import ChatInput from "./components/ChatInput";
import "./App.css";

const App = () => {
  const [isResponseComplete, setIsResponseComplete] = useState(true);
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState();
  const [messages, setMessages] = useState([
    {
      type: "bot",
      content:
        "Olden 1772'na hoş geldiniz! Sizi burada görmekten mutluluk duyuyoruz. Size nasıl yardımcı olabiliriz?",
    },
  ]);

  useEffect(() => {
    function generateThreadId() {
      const timestamp = Date.now(); // Current time in milliseconds
      const randomSuffix = Math.floor(Math.random() * 10000); // Random number to reduce collision
      return `thread-${timestamp}-${randomSuffix}`;
    }
    const threadId = generateThreadId();
    setThreadId(threadId);
  }, []);

  const sendMessage = async (message) => {
    setIsResponseComplete(false);
    setLoading(true);
    // Add user's message to chat and set completed to false for the incoming response
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "user", content: message, completed: true },
      { type: "bot", content: "", completed: false },
    ]);

    try {
      const chain = new RemoteRunnable({
        url: "https://api-ai-chatbot-restaurant.deeperincode.com",
      });

      const inputs = {
        input: message,
        messages: [],
        context: "",
        answer: "",
      };

      // Pass the correct structure for streamEvents
      const stream = await chain.streamEvents(inputs, {
        version: "v1",
        configurable: {
          thread_id: threadId,
        },
      });

      let botResponse = ""; // To accumulate chunks of the response
      // Process the stream and update the message content in real time
      for await (const event of stream) {
        // Verify the event structure and check for chunk content
        if (
          event["event"] === "on_chat_model_stream" && // Checks if the event type is "on_chat_model_stream"
          event["data"] && // Ensures that "data" exists
          event["data"]["chunk"] // Ensures that "chunk" exists within "data"
        ) {
          const chunk = event["data"]["chunk"];
          // Check if content exists before accessing it
          const chunkContent = chunk?.content; // Safe access using optional chaining
          if (chunkContent) {
            botResponse += chunkContent; // Accumulate the response content

            // Update the message content in real-time
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              const lastMessageIndex = updatedMessages.length - 1;
              updatedMessages[lastMessageIndex] = {
                ...updatedMessages[lastMessageIndex],
                content: botResponse,
                completed: false, // Message is still in progress
              };
              return updatedMessages;
            });
          } else {
            console.error("Content is missing in chunk:", chunk); // Log error if content is missing
          }
        }
      }

      // Mark the message as completed once all chunks are received
      setIsResponseComplete(true);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.length - 1;
        updatedMessages[lastMessageIndex] = {
          ...updatedMessages[lastMessageIndex],
          completed: true, // Message is completed
        };
        return updatedMessages;
      });
    } catch (error) {
      console.error("Error invoking the chatbot:", error);
    }
  };

  return (
    <>
      <main>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h1 className="title">Olden 1772 AI Alışveriş Asistanı</h1>
            </div>
            <div className="col-lg-12">
              {/* Pass messages as props to ChatWindow */}
              <ChatWindow messages={messages} />
            </div>
          </div>
        </div>
        <div className="form-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {/* ChatInput component */}
                <ChatInput
                  onSubmit={sendMessage}
                  isResponseComplete={isResponseComplete}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer class="fixed-bottom pb-3">
        <p className="text-light text-center m-0">
          powered by
          <a href="https://deeperincode.com/" class="fw-bold text-light">
            {" "}
            DeeperInCode
          </a>
        </p>
      </footer>
    </>
  );
};

export default App;